.login {
  max-width: 750px;
  margin: 0 auto;
  background-color: #fff;

  .text_align {
    text-align: center;
  }

  .logo_image {
    width: 40px;
    margin: 20px auto 0 auto;
  }

  .logo_title {
    margin-top: 15px;
    font-size: 18px;
    font-weight: 500;
    color: #16325c;
  }

  .form_item {
    margin: 20px 10px;
  }

  .form_input {
    width: 100%;
    height: 39px;
    outline: none;
    border-radius: 7px;
    font-size: 15px;
  }

  .form_input_code {
    width: 65%;
    height: 39px;
    outline: none;
    border-radius: 7px;
    font-size: 15px;
  }

  .form_input:focus {
    background-color: #F7FAFE;
  }

  .form_button_row {
    margin-top: 25px;
    text-align: center;
  }

  .form_button {
    width: 200px;
    height: 39px;
    background-color: #6179f8;
    color: #fff;
    font-weight: 700;
    border: none;
    border-radius: 90px;
    outline: none;
    cursor: pointer;
  }

  .form_button_sent {
    width: 32%;
    height: 39px;
    margin-left: 3%;
    background-color: #6179f8;
    color: #fff;
    font-weight: 700;
    border: none;
    border-radius: 90px;
    outline: none;
    cursor: pointer;
  }
  .form_button_sending {
    width: 32%;
    height: 39px;
    margin-left: 3%;
    background-color: #f1f3f4;
    color: #fff;
    font-weight: 700;
    border: none;
    border-radius: 90px;
    outline: none;
  }

  .form_validation_input {
    width: 70%;
    height: 39px;
    border-right: none;
    border-radius: 7px 0px 0px 7px;
    font-size: 15px;
    vertical-align: middle;
  }

  .form_validation_button {
    width: 30%;
    height: 39px;
    background-color: #6179f8;
    color: #fff;
    font-weight: 700;
    border-radius: 0px 7px 7px 0px;
    outline: none;
    cursor: pointer;
    vertical-align: middle;
    border: none;
  }

  .link_download{
    margin-top: 5px;
    font-size: 12px;
    color: #16325c;
    cursor: pointer;
  }

  .message_box {
    width: 325px;
    margin-top: 10px;
  }

}
